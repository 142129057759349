<template>
  <div class="personalCenter">
    <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="100px" :rules="rules" class="demo-ruleForm">
      <el-form-item label="账号名称">
        <el-input v-model="ruleForm.username" autocomplete="off" disabled></el-input>
      </el-form-item>
      <el-form-item label="账号密码" prop="password">
        <el-input type="password" v-model="ruleForm.password" show-password :disabled="!isEdit"></el-input>
        <el-popover
          placement="top"
          width="160"
          v-model="visible">
          <p>是否需要修改密码？</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="visible = false">取消</el-button>
            <el-button type="primary" size="mini" @click="enterEvent">确定</el-button>
          </div>
          <i v-show="!isEdit" slot="reference" class="el-icon-edit editIcon"></i>
        </el-popover>
        <el-popover
          placement="top"
          width="160"
          v-model="isVisible">
          <p>是否需要修改密码？</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="cancelEvent('ruleForm')">取消</el-button>
            <el-button type="primary" size="mini" @click="editPassEvent('ruleForm')">确定</el-button>
          </div>
          <span class="btn" v-show="isEdit" slot="reference">确 认</span>
        </el-popover>
        <!-- <span class="btn" v-if="isEdit" @click="editPassEvent('ruleForm')">确 认</span> -->
      </el-form-item>
      <el-form-item label="姓名">
        <el-input  v-model="ruleForm.realName" disabled></el-input>
      </el-form-item>
      <el-form-item label="电话">
        <el-input v-model="ruleForm.phone" disabled></el-input>
      </el-form-item>
      <el-form-item label="角色">
        <el-input v-model="ruleForm.roleNames" autocomplete="off" disabled></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import way from '../api/encapsulation'
export default {
  name: 'personalCenter',
  data() {
    var validQC = (rule, value, callback) => {
      callback(way.regChinese(value))
    };
    var validateInput = (rule, value, callback) => {
      callback(way.regSpecil(value))
    };
    var validNumletter = (rule, value, callback) => {
      callback(way.regNumletter(value))
    };
    return {
      ruleForm: {
        username: '',
        password: '****',
        realName: '',
        phone: '',
        roleNames:'',
      },
      rules: {
        password: [
          { required: true, message: '密码为必填', trigger: 'blur' },
          { min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'blur' },
          { validator: validQC, trigger: ["blur", "change"] },
          // { validator: validateInput, trigger: ["blur", "change"] },
          { validator: validNumletter, trigger: ["blur", "change"] },
        ]
      },
      isEdit: false,
      visible: false,
      isVisible: false,
    }
  },
  created() {
    this.$request.getPersonCenterInfo(res => {
      this.ruleForm.username = res.data.accountName;
      this.ruleForm.realName = res.data.realName;
      this.ruleForm.phone = res.data.phone;
      this.ruleForm.roleNames = res.data.roleNames;
    })
  },
  methods: {
    enterEvent() {
      this.visible = false;
      this.isEdit = !this.isEdit;
    },
    cancelEvent(formName) {
      this.ruleForm.password = '';
      this.isEdit = !this.isEdit;
      this.isVisible = false;
      this.$refs[formName].resetFields();
    },
    editPassEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if(valid) {
          let obj = {
            "confirmPassword": this.ruleForm.password,
            "nwPassword": this.ruleForm.password
          }
          let token = sessionStorage.getItem('token')
          this.$request.firstLogin(obj, token).then(res=>{
            if(res.data.code == 0) {
              this.Success(res.data.data);
              this.ruleForm.password = '';
              this.isEdit = !this.isEdit;
              this.isVisible = false;
              this.$refs[formName].resetFields();
            }
          })
        } else {
          return false;
        }
      })
    }
  }
}
</script>

<style lang='less'>
  .personalCenter {
    background-color: #fff;
    padding: 20px;
    .el-form {
      width: 600px;
      .el-form-item {
        &:nth-of-type(2) {
          width: 650px;
          .el-form-item__content {
            width: 550px;
            display: flex;
            align-items: center;
            .el-input {
              width: 500px;
            }
            .editIcon {
              font-size: 18px;
              margin-left: 10px;
              cursor: pointer;
            }
            .btn {
              display: inline-block;
              color: #409EFF;
              cursor: pointer;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
</style>
